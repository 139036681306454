import React from "react";
import { Container } from "@mui/material";
import AppBarComponent from "../components/AppBarComponent";
import AboutSection from "../components/sections/AboutSection";
import HowItWorksSection from "../components/sections/HowItWorksSection";
import ReviewSection from "../components/sections/ReviewSection";
import ContactSection from "../components/sections/ContactSection";

function LandingPage() {
  return (
    <div>
      <AppBarComponent />
      <Container maxWidth="lg">
        <AboutSection />
        <HowItWorksSection />
        <ReviewSection />
        <ContactSection />
      </Container>
    </div>
  );
}

export default LandingPage;
