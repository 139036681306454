import React from "react";
import { Box, Typography, ListItem, ListItemText } from "@mui/material";

const TermsConditions = () => {
  const CustomListItem = ({ letter, children }) => (
    <ListItem
      sx={{
        display: "flex",
        paddingLeft: 0,
        listStyleType: "none",
        alignItems: "flex-start",
      }}
    >
      <Typography
        variant="body1"
        component="span"
        sx={{
          minWidth: "2em",
          display: "inline-block",
          textAlign: "right",
          marginRight: "1em",
          fontFamily: "inherit !important",
        }}
      >
        {letter}
      </Typography>
      <ListItemText
        primary={children}
        sx={{ margin: 0 }}
        primaryTypographyProps={{
          sx: {
            fontFamily: "inherit !important",
          },
        }}
      />
    </ListItem>
  );

  return (
    <Box
      sx={{
        padding: "3rem",
        backgroundColor: "#f5f5f5",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          backgroundColor: "white",
          padding: "2rem 1rem",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.7)", // Adjust the values as needed
          borderRadius: "8px",
        }}
      >
        <Typography
          variant="h6"
          style={{
            fontSize: "1.9rem",
            textAlign: "center",
            margin: "0rem 3rem",
            fontFamily: "inherit !important",
          }}
        >
          <strong>STEADY INNOVATION CORPORATION PRIVACY POLICY</strong>
        </Typography>
        <Typography
          variant="p"
          sx={{
            textAlign: "center",
            marginBottom: "20px",
            fontFamily: "inherit !important",
          }}
        >
          (the "<strong>Terms</strong>")
        </Typography>
        <Typography
          sx={{
            textAlign: "center",
            marginBottom: "20px",
            fontFamily: "inherit !important",
          }}
        >
          Effective Date:
        </Typography>
        <Typography
          style={{
            fontFamily: "inherit !important",
            marginBottom: "20px",
            fontSize: "1.2rem",
          }}
        >
          <strong>
            PLEASE READ THESE TERMS CAREFULLY BEFORE ACCESSING OR USING OUR
            SERVICES.
          </strong>
        </Typography>
        <Typography
          variant="p"
          marginTop={"20px"}
          style={{
            fontFamily: "inherit !important",
            textDecoration: "underline",
          }}
        >
          <strong>1.&nbsp;&nbsp;&nbsp;CONTRACTUAL RELATIONSHIP</strong>
        </Typography>
        <Typography
          style={{ fontFamily: "inherit !important", marginBottom: "20px" }}
        >
          These Terms and Conditions (the “<strong>Terms</strong>”) form a legal
          and binding agreement between you, the end user (“<strong>you</strong>
          ” or “<strong>your</strong>”), and Steady Innovation Corporation, a
          Newfoundland and Labrador (Canada) corporation operating under Steady
          Innovation, Sidekick, and other brands (“<strong>Sidekick</strong>”, “
          <strong>we</strong>”, “<strong>us</strong>”, or “<strong>our</strong>
          ”), and governs your access or use of any of our websites, platforms,
          applications, products and services, including but not limited to the
          Sidekick platform as provided at{" "}
          <a
            style={{ color: "#00BFFF", fontFamily: "inherit !important" }}
            href="https://www.sidekick-ai.ninja"
            target="_blank"
            rel="noreferrer"
          >
            www.sidekick-ai.ninja
          </a>{" "}
          which may be redesignated from time to time (“<strong>Website</strong>
          ”) and the Sidekick mobile application (referred to herein as the “
          <strong>Services</strong>” and more fully defined below).
        </Typography>
        <Typography
          style={{ fontFamily: "inherit !important", marginBottom: "20px" }}
        >
          By accessing or using the Services, you agree to be legally bound by
          all of the terms and conditions of these Terms. If you do not agree to
          these Terms, you may not access or use the Services. These Terms
          expressly supersede prior agreements or arrangements with you. We may
          immediately terminate these Terms or any of the Services with respect
          to you, or generally cease or deny access to the Services or any
          portion thereof, at any time for any reason.
        </Typography>
        <Typography
          style={{ fontFamily: "inherit !important", marginBottom: "20px" }}
        >
          These Terms will govern any and all updates and upgrades provided by
          Sidekick that replace or supplement the original Services, unless such
          upgrade is accompanied by new terms. Supplemental terms may apply to
          certain Services, such as policies for a particular event, program,
          activity or promotion, and such supplemental terms will be disclosed
          to you in a separate disclosure or in connection with the applicable
          Services. Supplemental terms are in addition to, and shall be deemed a
          part of, the Terms for the purposes of the applicable Services.
          Supplemental terms shall prevail over these Terms in the event of a
          conflict with respect to the applicable Services.
        </Typography>
        <Typography
          style={{ fontFamily: "inherit !important", marginBottom: "20px" }}
        >
          We may amend the Terms from time to time. Amendments will be effective
          upon our posting of such updated Terms at this location or in the
          amended policies or supplemental terms on the applicable Service. Your
          continued access or use of the Services after such posting confirms
          your consent to be bound by the Terms, as amended.
        </Typography>
        <Typography
          style={{ fontFamily: "inherit !important", marginBottom: "20px" }}
        >
          Our collection and use of personal information in connection with the
          Services is as provided in our Privacy Policy found on our Website. We
          may provide to a claims processor or an insurer any necessary
          information (including your contact information) if there is a
          complaint, dispute or conflict and such information or data is
          necessary to resolve the complaint, dispute or conflict.
        </Typography>
        <Typography
          variant="p"
          marginTop={"20px"}
          style={{
            fontFamily: "inherit !important",
            textDecoration: "underline",
          }}
        >
          <strong>2.&nbsp;&nbsp;&nbsp;SERVICES</strong>
        </Typography>
        <Typography
          style={{ fontFamily: "inherit !important", marginBottom: "20px" }}
        >
          The Services constitute a technology platform that enables users of
          our Sidekick platform as provided on our Website and mobile
          applications provided as part of the Services (each, an “
          <strong>Application</strong>”) access to an personal assistance tool
          powered by artificial intelligence to simplify user tasks and
          enhancing everyday productivity through intuitive machine learning
          capabilities.{" "}
        </Typography>
        <Typography
          style={{ fontFamily: "inherit !important", marginBottom: "20px" }}
        >
          <strong>License Grant</strong>
        </Typography>
        <Typography
          style={{ fontFamily: "inherit !important", marginBottom: "20px" }}
        >
          Subject to your compliance with these Terms, Sidekick hereby grants
          you a limited, non-exclusive, non-sublicensable, revocable,
          non-transferable license to: (i) access and use the Applications on
          your personal device solely in connection with your use of the
          Services; and (ii) access and use any content, information and related
          materials that may be made available through the Services.
        </Typography>
        <Typography
          style={{ fontFamily: "inherit !important", marginBottom: "20px" }}
        >
          Sidekick reserves all rights not expressly granted to you under these
          Terms.
        </Typography>
        <Typography
          style={{ fontFamily: "inherit !important", marginBottom: "20px" }}
        >
          <strong>Restrictions</strong>
        </Typography>
        <Typography
          style={{ fontFamily: "inherit !important", marginBottom: "20px" }}
        >
          You may not, and shall not permit others, including but not limited to
          third parties, to directly or indirectly: (i) remove any copyright,
          trademark or other proprietary notices from any portion of the
          Services; (ii) reproduce, modify, prepare derivative works based upon,
          distribute, license, lease, sell, resell, transfer, publicly display,
          publicly perform, transmit, stream, broadcast or otherwise exploit the
          Services except as expressly permitted by Sidekick; (iii) decompile,
          reverse engineer or disassemble the Applications or any part of the
          Services except as may be permitted by applicable law; (iv) link to,
          mirror or frame any portion of the Services; (v) cause or launch any
          programs or scripts for the purpose of scraping, indexing, surveying,
          or otherwise data mining any portion of the Services or unduly
          burdening or hindering the operation and/or functionality of any
          aspect of the Services; or (vi) attempt to gain unauthorized access to
          or impair any aspect of the Services or its related systems or
          networks.
        </Typography>
        <Typography
          style={{ fontFamily: "inherit !important", marginBottom: "20px" }}
        >
          <strong>Provision of the Services</strong>
        </Typography>
        <Typography
          style={{ fontFamily: "inherit !important", marginBottom: "20px" }}
        >
          You acknowledge that portions of the Services may be made available
          under Sidekick’s various brands. You also acknowledge that the
          Services may be made available under such brands or in connection
          with: (i) certain of Sidekicks subsidiaries and affiliates; or (ii)
          Third-Party Providers (defined below).{" "}
        </Typography>
        <Typography
          style={{ fontFamily: "inherit !important", marginBottom: "20px" }}
        >
          <strong>Third Party Services</strong>
        </Typography>
        <Typography
          style={{ fontFamily: "inherit !important", marginBottom: "20px" }}
        >
          The Services may be made available or accessed using Third-Party
          Applications (defined below) that Sidekick does not control (which
          includes advertising). You acknowledge that different terms of use and
          privacy policies may apply to your use of such Third-Party
          Applications (defined below). In no event shall Sidekick be
          responsible or liable for any products or services of such Third-Party
          Applications.{" "}
        </Typography>
        <Typography
          style={{ fontFamily: "inherit !important", marginBottom: "20px" }}
        >
          Apple Inc., Google, Inc., Microsoft Corporation, BlackBerry Limited
          and/or their applicable international subsidiaries and affiliates (“
          <strong>Third-Party Providers</strong>”) will be beneficiaries to this
          contract if you access the Services using Applications developed for
          Apple iOS, Android, Microsoft Windows, or Blackberry-powered mobile
          devices, respectively (“<strong>Third-Party Applications</strong>”).
          These Third-Party Providers are not parties to this contract and are
          not responsible for the provision or support of the Services in any
          manner. Your access to the Services using these Third-Party
          Applications is subject to terms set forth in the applicable
          Third-Party Provider’s terms of service.{" "}
        </Typography>
        <Typography
          style={{ fontFamily: "inherit !important", marginBottom: "20px" }}
        >
          The Services shall only be accessed through the Website and
          Third-Party Applications.{" "}
        </Typography>
        <Typography
          style={{ fontFamily: "inherit !important", marginBottom: "20px" }}
        >
          <strong>Ownership</strong>
        </Typography>
        <Typography
          style={{ fontFamily: "inherit !important", marginBottom: "20px" }}
        >
          The Services and all rights therein are and shall remain Sidekick’s
          property or the property of Sidekick’s licensors. Neither these Terms
          nor your use of the Services convey or grant to you any rights: (i) in
          or related to the Services except for the limited license granted
          above; or (ii) to use or reference in any manner Sidekick’s company
          names, logos, product and service names, trademarks or services marks
          or those of Sidekick’s licensors.
        </Typography>
        <Typography
          variant="p"
          marginTop={"20px"}
          style={{
            fontFamily: "inherit !important",
            textDecoration: "underline",
          }}
        >
          <strong>3.&nbsp;&nbsp;&nbsp;USAGE RULES</strong>
        </Typography>
        <Typography
          style={{ fontFamily: "inherit !important", marginBottom: "20px" }}
        >
          <strong>User Accounts</strong>
        </Typography>
        <Typography
          style={{ fontFamily: "inherit !important", marginBottom: "20px" }}
        >
          In order to use most aspects of the Services, you must register for
          and maintain an active personal user Services account (“
          <strong>Account</strong>”). You must be at least 18 years of age, or
          the age of legal majority in the jurisdiction you reside when
          accessing the services (if different than 18), to obtain an Account.
          Account registration requires you to submit to Sidekick certain
          personal information, such as your name, address, mobile phone number
          and age, as well as at least one valid payment method (either a credit
          card or accepted payment partner). You agree to maintain accurate,
          complete, and up-to-date information in your Account. Your failure to
          maintain accurate, complete, and up-to-date Account information,
          including having an invalid or expired payment method on file, may
          result in your inability to access and use the Services or Sidekick’s
          termination of these Terms with you. You are responsible for all
          activity that occurs under your Account, and you agree to maintain the
          security and secrecy of your Account username and password at all
          times. Unless otherwise permitted by Sidekick in writing, you may only
          possess one Account.
        </Typography>
        <Typography
          style={{ fontFamily: "inherit !important", marginBottom: "20px" }}
        >
          <strong>User Requirements and Conduct</strong>
        </Typography>
        <Typography
          style={{ fontFamily: "inherit !important", marginBottom: "20px" }}
        >
          You may not authorize third parties to use your Account. You may not
          assign or otherwise transfer your Account to any other person or
          entity. You agree to comply with all applicable laws when using the
          Services and you may only use the Services for lawful purposes. You
          will not, in your use of the Services, cause any of the following, to
          occur:
        </Typography>
        <Box sx={{ pl: 2 }}>
          <CustomListItem letter="(i)">
            transmit spam, chain letters, or other unsolicited communications
            with any third party;
          </CustomListItem>
          <CustomListItem letter="(ii)">
            interfere or attempt to interfere with or compromise the Services
            integrity or security of, or decipher any transmissions to or from
            the servers that may be running the Services;{" "}
          </CustomListItem>
          <CustomListItem letter="(iii)">
            taking any action that imposes, or may impose (as determined by us,
            in our sole discretion), an unreasonable or disproportionately large
            load on our infrastructure;{" "}
          </CustomListItem>
          <CustomListItem letter="(iv)">
            uploading invalid data, viruses, worms, or other software agents
            through the Services;{" "}
          </CustomListItem>
          <CustomListItem letter="(v)">
            collecting or harvesting any personal information, including account
            names, from the Services;
          </CustomListItem>
          <CustomListItem letter="(vi)">
            impersonating another person or otherwise misrepresenting your
            affiliation with a person or entity, conducting fraud, or hiding or
            attempting to hide your identity;
          </CustomListItem>
          <CustomListItem letter="(vii)">
            interfering with the proper working of the Services; and{" "}
          </CustomListItem>
          <CustomListItem letter="(viii)">
            access the Services through any technology or means other than those
            provided or authorized by the Terms.
          </CustomListItem>
        </Box>
        <Typography
          style={{ fontFamily: "inherit !important", marginBottom: "20px" }}
        >
          <strong>Text Messaging</strong>
        </Typography>
        <Typography
          style={{ fontFamily: "inherit !important", marginBottom: "20px" }}
        >
          By creating an Account, you agree that the Services may send you text
          (SMS) messages as part of the normal business operation of your use of
          the Services. You may opt-out of receiving text (SMS) messages from
          Sidekick at any time by following the directions found at the Website.
          You acknowledge that opting out of receiving text (SMS) messages may
          impact your use of the Services.
        </Typography>
        <Typography
          style={{ fontFamily: "inherit !important", marginBottom: "20px" }}
        >
          <strong>Promotional Codes</strong>
        </Typography>
        <Typography
          style={{ fontFamily: "inherit !important", marginBottom: "20px" }}
        >
          Sidekick may, in Sidekick’s sole discretion, create promotional codes
          that may be redeemed for Account credit, or other features or benefits
          related to the Services subject to any additional terms that Sidekick
          establishes on a per promotional code basis (“
          <strong>Promo Codes</strong>”). You agree that Promo Codes: (i) must
          be used for the intended audience and purpose, and in a lawful manner;
          (ii) may not be duplicated, sold or transferred in any manner, or made
          available to the general public (whether posted to a public form or
          otherwise), unless expressly permitted by Sidekick; (iii) may be
          disabled by Sidekick at any time for any reason without liability to
          Sidekick; (iv) may only be used pursuant to the specific terms that
          Sidekick establishes for such Promo Code; (v) are not valid for cash;
          and (vi) may expire prior to your use. Sidekick reserves the right to
          withhold or deduct credits or other features or benefits obtained
          through the use of Promo Codes by you or any other user in the event
          that Sidekick determines or believes that the use or redemption of the
          Promo Code was in error, fraudulent, illegal, or in violation of the
          applicable Promo Code terms or these Terms.
        </Typography>
        <Typography
          style={{ fontFamily: "inherit !important", marginBottom: "20px" }}
        >
          <strong>User Provided Content</strong>
        </Typography>
        <Typography
          style={{ fontFamily: "inherit !important", marginBottom: "20px" }}
        >
          Sidekick may, in Licensor’s sole discretion, permit you from time to
          time to submit, upload, publish or otherwise make available to
          Sidekick through the Services, textual, audio, and/or visual content
          and information, including commentary and feedback related to the
          Services, initiation of support requests, and submission of entries
          for competitions and promotions (“<strong>User Content</strong>”). Any
          User Content provided by you remains your property. However, by
          providing User Content to Sidekick, you grant Sidekick a worldwide,
          perpetual, irrevocable, transferrable, royalty-free license, with the
          right to sublicense, to use, copy, modify, create derivative works of,
          distribute, publicly display, publicly perform, and otherwise exploit
          in any manner such User Content in all formats and distribution
          channels now known or hereafter devised (including in connection with
          the Services and Sidekick’s business and on third-party sites and
          services), without further notice to or consent from you, and without
          the requirement of payment to you or any other person or entity.
        </Typography>
        <Typography
          style={{ fontFamily: "inherit !important", marginBottom: "20px" }}
        >
          You represent and warrant that: (i) you either are the sole and
          exclusive owner of all User Content or you have all rights, licenses,
          consents and releases necessary to grant Sidekick the license to the
          User Content as set forth above; and (ii) neither the User Content nor
          your submission, uploading, publishing or otherwise making available
          of such User Content nor Sidekick’s use of the User Content as
          permitted herein will infringe, misappropriate or violate a
          third-party’s intellectual property or proprietary rights, or rights
          of publicity or privacy, or result in the violation of any applicable
          law or regulation.
        </Typography>
        <Typography
          style={{ fontFamily: "inherit !important", marginBottom: "20px" }}
        >
          You agree to not provide User Content that is defamatory, libelous,
          hateful, violent, obscene, pornographic, unlawful, or otherwise
          offensive, as determined by Sidekick in its sole discretion, whether
          or not such material may be protected by law. Sidekick may, but shall
          not be obligated to, review, monitor, or remove User Content, at
          Sidekick’s sole discretion and at any time and for any reason, without
          notice to you.
        </Typography>
        <Typography
          style={{ fontFamily: "inherit !important", marginBottom: "20px" }}
        >
          <strong>Network Access and Devices</strong>
        </Typography>
        <Typography
          style={{ fontFamily: "inherit !important", marginBottom: "20px" }}
        >
          You are responsible for obtaining the data and internet network access
          necessary to use the Services. Your mobile network’s data and
          messaging rates and fees may apply if you access or use the Services
          from a wireless-enabled device and you shall be responsible for such
          rates and fees. You are responsible for acquiring and updating
          compatible hardware or devices necessary to access and use the
          Services and Applications and any updates thereto. Sidekick does not
          guarantee that the Services, or any portion thereof, will function on
          any particular hardware or devices. In addition, the Services may be
          subject to malfunctions and delays inherent in the use of the internet
          and electronic communications.
        </Typography>
        <Typography
          variant="p"
          marginTop={"20px"}
          style={{
            fontFamily: "inherit !important",
            textDecoration: "underline",
          }}
        >
          <strong>4.&nbsp;&nbsp;&nbsp;PAYMENT</strong>
        </Typography>
        <Typography
          style={{ fontFamily: "inherit !important", marginBottom: "20px" }}
        >
          The Services shall be provided based on pricing and payment terms
          applicable to you and available through our Applications (as we may
          update them from time to time, the “<strong>Pricing</strong> and{" "}
          <strong>Payment Terms</strong>”). Sidekick may add or amend Pricing
          and Payment Terms for the Services, at any time, in its sole
          discretion; provided however, that if we have offered a recurring
          amount you pay as consideration for a subscription to the Services for
          a specific duration and for your use of the Services (“
          <strong>Subscription Fee</strong>”), we agree that the Subscription
          Fee will remain in force for that duration and any change to our
          Pricing and Payment Terms will become effective in the billing cycle
          after the duration of the initial Subscription Fee has expired.
        </Typography>
        <Typography
          style={{ fontFamily: "inherit !important", marginBottom: "20px" }}
        >
          In connection with payments of the Subscription Fee, you may be asked
          to provide customary billing information such as name, billing
          address, and credit card. All information that you provide must be
          accurate, complete, and current. You agree to pay all charges incurred
          by us in connection with the payment method used to pay the
          Subscription Fee. You hereby authorize the collection of such amounts
          by charging your designated payment method or credit or debit card. We
          cannot control any fees that may be charged to you by your bank in
          connection with the collection of Subscription Fees or any other fees,
          and we disclaim any and all liability in this regard. If you are
          directed to a Third-Party Provider, you will be subject to terms and
          conditions governing the use of that Third-Party Application and its
          personal information collection practices. Please review such terms
          and conditions and privacy notice before using such Third-Party
          Applications. Without limiting the generality of the foregoing, you
          acknowledge and understand that, by using the Services, you agree to
          be bound by the terms of service of any other Third-Party Provider
          that may apply for use of the Third-Party Application. You acknowledge
          and understand that a Third-Party Provider’s payment processors may
          collect and retain fees whenever you pay Subscription Fees. Except as
          otherwise provided herein, Subscription Fees are non-refundable. You
          will pay all applicable taxes, if any, relating to any monetary
          transaction interaction with the Service.
        </Typography>
        <Typography
          variant="p"
          marginTop={"20px"}
          style={{
            fontFamily: "inherit !important",
            textDecoration: "underline",
          }}
        >
          <strong>
            5.&nbsp;&nbsp;&nbsp;LIMITATION OF LIABILITY, DISCLAIMERS AND
            INDEMNIFICATION
          </strong>
        </Typography>
        <Typography
          style={{ fontFamily: "inherit !important", marginBottom: "20px" }}
        >
          <strong>DISCLAIMER</strong>
        </Typography>
        <Typography
          style={{ fontFamily: "inherit !important", marginBottom: "20px" }}
        >
          THE SERVICES ARE PROVIDED “AS IS” AND “AS AVAILABLE.” SIDEKICK
          DISCLAIMS ALL REPRESENTATIONS AND WARRANTIES, EXPRESS, IMPLIED OR
          STATUTORY, NOT EXPRESSLY SET OUT IN THESE TERMS, INCLUDING THE IMPLIED
          WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND
          NON-INFRINGEMENT. IN ADDITION, SIDEKICK MAKES NO REPRESENTATION,
          WARRANTY, OR GUARANTEE REGARDING THE RELIABILITY, TIMELINESS, QUALITY,
          SUITABILITY OR AVAILABILITY OF THE SERVICES, OR THAT THE SERVICES WILL
          BE UNINTERRUPTED OR ERROR-FREE. YOU AGREE THAT THE ENTIRE RISK ARISING
          OUT OF YOUR USE OF THE SERVICES, AND ANY USE OF THE SERVICES IN
          CONNECTION WITH ANY COMMERCIAL OR BUSINESS PURPOSES IN CONNECTION
          THEREWITH, REMAINS SOLELY WITH YOU, TO THE MAXIMUM EXTENT PERMITTED
          UNDER APPLICABLE LAW.
        </Typography>
        <Typography
          style={{ fontFamily: "inherit !important", marginBottom: "20px" }}
        >
          <strong>LIMITATION OF LIABILITY</strong>
        </Typography>
        <Typography
          style={{ fontFamily: "inherit !important", marginBottom: "20px" }}
        >
          SIDEKICK SHALL NOT BE LIABLE FOR INDIRECT, INCIDENTAL, SPECIAL,
          EXEMPLARY, PUNITIVE OR CONSEQUENTIAL DAMAGES, INCLUDING LOST PROFITS,
          LOST DATA, PERSONAL INJURY OR PROPERTY DAMAGE RELATED TO, IN
          CONNECTION WITH, OR OTHERWISE RESULTING FROM ANY USE OF THE SERVICES,
          EVEN IF SIDEKICK HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
          SIDEKICK SHALL NOT BE LIABLE FOR ANY DAMAGES, LIABILITY OR LOSSES
          ARISING OUT OF: (i) YOUR USE OF OR RELIANCE ON THE SERVICES OR YOUR
          INABILITY TO ACCESS OR USE THE SERVICES; OR (ii) ANY TRANSACTION OR
          RELATIONSHIP, COMMERCIAL OR OTHERWISE, BETWEEN YOU AND ANY THIRD
          PARTY, EVEN IF SIDEKICK HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH
          DAMAGES. SIDEKICK SHALL NOT BE LIABLE FOR DELAY OR FAILURE IN
          PERFORMANCE RESULTING FROM CAUSES BEYOND SIDEKICK’S REASONABLE
          CONTROL. IN NO EVENT SHALL SIDEKICK’S TOTAL LIABILITY TO YOU IN
          CONNECTION WITH THE SERVICES FOR ALL DAMAGES, LOSSES AND CAUSES OF
          ACTION EXCEED FIVE HUNDRED CANADIAN DOLLARS ($500).
        </Typography>
        <Typography
          style={{ fontFamily: "inherit !important", marginBottom: "20px" }}
        >
          THE LIMITATIONS AND DISCLAIMER IN THIS SECTION 5 DO NOT PURPORT TO
          LIMIT LIABILITY OR ALTER YOUR RIGHTS AS A CONSUMER THAT CANNOT BE
          EXCLUDED UNDER APPLICABLE LAW.
        </Typography>
        <Typography
          style={{ fontFamily: "inherit !important", marginBottom: "20px" }}
        >
          <strong>Indemnity</strong>
        </Typography>
        <Typography
          style={{ fontFamily: "inherit !important", marginBottom: "20px" }}
        >
          You agree to indemnify and hold Sidekick and its officers, directors,
          employees and agents harmless from any and all claims, demands,
          losses, liabilities, and expenses (including attorneys’ fees) arising
          out of or in connection with: (i) your use of the Services; (ii) your
          breach or violation of any of these Terms; (iii) Sidekick’s use of
          your User Content; or (iv) your violation of the rights of any third
          party.{" "}
        </Typography>
        <Typography
          variant="p"
          marginTop={"20px"}
          style={{
            fontFamily: "inherit !important",
            textDecoration: "underline",
          }}
        >
          <strong>6.&nbsp;&nbsp;&nbsp;GOVERNING LAW AND ARBITRATION</strong>
        </Typography>
        <Typography
          style={{ fontFamily: "inherit !important", marginBottom: "20px" }}
        >
          Except as otherwise set forth in these Terms, these Terms shall be
          exclusively governed by and construed in accordance with the laws of
          the Province of Newfoundland and Labrador and the federal laws of
          Canada applicable therein, excluding its rules on conflicts of laws.
          The United Nations Convention on Contracts for the International Sale
          of Goods and the International Sale of Goods Act, RSNL 1990, c I-16
          shall not apply. Any dispute, conflict, claim or controversy arising
          out of or broadly in connection with or relating to the Services or
          these Terms, including those relating to its validity, its
          construction or its enforceability (any “<strong>Dispute</strong>”)
          shall be mandatorily submitted to binding arbitration pursuant to the
          Arbitration Act, RSNL 1990, c A-14.{" "}
        </Typography>
        <Typography
          variant="p"
          marginTop={"20px"}
          style={{
            fontFamily: "inherit !important",
            textDecoration: "underline",
          }}
        >
          <strong>7.&nbsp;&nbsp;&nbsp;OTHER</strong>
        </Typography>
        <Typography
          style={{ fontFamily: "inherit !important", marginBottom: "20px" }}
        >
          <strong>Copyright Infringement</strong>
        </Typography>
        <Typography
          style={{ fontFamily: "inherit !important", marginBottom: "20px" }}
        >
          Sidekick respects the intellectual property of others. If you believe
          in good faith that any content on the Services infringes upon your
          copyrights, please send the following to us at{" "}
          <a
            style={{ color: "#00BFFF", fontFamily: "inherit !important" }}
            href="mailto:hello@sicdev.ninja?subject=Your Subject Here&body=Your Message Here"
          >
            hello@sicdev.ninja
          </a>
          :
        </Typography>
        <Box sx={{ pl: 2 }}>
          <CustomListItem letter="(i)">
            identification of the copyrighted work(s) claimed to have been
            infringed;
          </CustomListItem>
          <CustomListItem letter="(ii)">
            identification of the material that is claimed to be infringing or
            to be the subject of infringing activity and that is to be removed
            or access to which is to be disabled, and information reasonably
            sufficient to permit us to locate the material;"
          </CustomListItem>
          <CustomListItem letter="(iii)">
            your mailing address, telephone number and email address;
          </CustomListItem>
          <CustomListItem letter="(iv)">
            a statement by you that you have a good faith belief that use of the
            material in the manner complained of is not authorized by the
            copyright owner, its agent, or the law
          </CustomListItem>
          <CustomListItem letter="(v)">
            a statement by you, made under penalty of perjury, that the
            information in your notification is accurate, and that you are the
            copyright owner or are authorized to act on the copyright owner’s
            behalf; and;
          </CustomListItem>
          <CustomListItem letter="(vi)">
            a physical or electronic signature of the copyright owner or the
            person authorized to act on behalf of the owner of the copyright
            interest.
          </CustomListItem>
        </Box>
        <Typography
          style={{ fontFamily: "inherit !important", marginBottom: "20px" }}
        >
          <strong>Notice</strong>
        </Typography>
        <Typography
          style={{ fontFamily: "inherit !important", marginBottom: "20px" }}
        >
          Sidekick may give notice by means of a general notice on the Services,
          electronic mail to your email address in your Account, or by written
          communication sent to your address as set forth in your Account. You
          may give notice to Sidekick by written communication to Sidekick’s
          address at:
        </Typography>
        <Typography
          style={{ fontFamily: "inherit !important", textAlign: "center" }}
        >
          Steady Innovation Corporation
        </Typography>
        <Typography
          style={{ fontFamily: "inherit !important", textAlign: "center" }}
        >
          3-86 West Street
        </Typography>
        <Typography
          style={{ fontFamily: "inherit !important", textAlign: "center" }}
        >
          Corner Brook
        </Typography>
        <Typography
          style={{ fontFamily: "inherit !important", textAlign: "center" }}
        >
          Newfoundland & Labrador
        </Typography>
        <Typography
          style={{ fontFamily: "inherit !important", textAlign: "center" }}
        >
          Canada
        </Typography>
        <Typography
          style={{ fontFamily: "inherit !important", textAlign: "center" }}
        >
          A2H 2Z3
        </Typography>
        <Typography
          style={{ fontFamily: "inherit !important", marginBottom: "20px" }}
        >
          <strong>General</strong>
        </Typography>
        <Typography
          style={{ fontFamily: "inherit !important", marginBottom: "20px" }}
        >
          You may not assign or transfer these Terms in whole or in part without
          Sidekick’s prior written approval. You give your approval to Sidekick
          for it to assign or transfer these Terms in whole or in part,
          including to: (i) a subsidiary or affiliate; (ii) an acquirer of
          Sidekick’s equity, business or assets; or (iii) a successor by merger.
          No joint venture, partnership, employment or agency relationship
          exists between you or Sidekick as a result of the contract between you
          and Sidekick or use of the Services. No failure or delay in exercising
          any rights under these Terms will operate as a waiver of such rights.
        </Typography>
        <Typography
          style={{ fontFamily: "inherit !important", marginBottom: "20px" }}
        >
          If any provision of these Terms is held to be illegal, invalid or
          unenforceable, in whole or in part, under any law, such provision or
          part thereof shall to that extent be deemed not to form part of these
          Terms but the legality, validity and enforceability of the other
          provisions in these Terms shall not be affected. In that event, the
          parties shall replace the illegal, invalid or unenforceable provision
          or part thereof with a provision or part thereof that is legal, valid
          and enforceable and that has, to the greatest extent possible, a
          similar effect as the illegal, invalid or unenforceable provision or
          part thereof, given the contents and purpose of these Terms. These
          Terms constitute the entire agreement and understanding of the parties
          with respect to its subject matter and replaces and supersedes all
          prior or contemporaneous agreements or undertakings regarding such
          subject matter. In these Terms, the words “including” and “include”
          mean “including, but not limited to.”
        </Typography>
      </Box>
    </Box>
  );
};

export default TermsConditions;
