import React, { useState, useEffect } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  useMediaQuery,
  useTheme,
  Box,
  Slide,
  useScrollTrigger,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { Link as ScrollLink } from "react-scroll";

function HideOnScroll({ children }) {
  const trigger = useScrollTrigger();
  const [scrollDirection, setScrollDirection] = useState("up");
  const [lastScrollY, setLastScrollY] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      if (currentScrollY > lastScrollY && currentScrollY > 100) {
        setScrollDirection("down");
      } else {
        setScrollDirection("up");
      }
      setLastScrollY(currentScrollY);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [lastScrollY]);

  return (
    <Slide appear={false} direction="down" in={scrollDirection === "up"}>
      {children}
    </Slide>
  );
}

function AppBarComponent() {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const menuItems = [
    { to: "about", text: "About" },
    { to: "how-it-works", text: "How it Works" },
    { to: "reviews", text: "Reviews" },
    { to: "contact", text: "Contact" },
  ];

  return (
    <>
      <HideOnScroll>
        <AppBar
          position="fixed"
          sx={{ zIndex: theme.zIndex.drawer + 1, backgroundColor: "#487de7" }}
        >
          <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
            {/* <Typography variant="h6" style={{ flexGrow: 1 }}>
              Sidekick-AI
            </Typography> */}
            <ScrollLink
              key={"about"}
              to={"about"}
              smooth={true}
              duration={500}
              spy={true}
              offset={-70}
            >
              <Typography style={{ cursor: "pointer", color: "white" }}>
                Sidekick-AI
              </Typography>
            </ScrollLink>
            {isMobile ? (
              <>
                <IconButton
                  edge="start"
                  color="inherit"
                  aria-label="menu"
                  onClick={handleDrawerToggle}
                >
                  <MenuIcon />
                </IconButton>
              </>
            ) : (
              <div
                style={{
                  display: "flex",
                  marginLeft: "auto",
                }}
              >
                {menuItems.map((item) => (
                  <ScrollLink
                    key={item.to}
                    to={item.to}
                    smooth={true}
                    duration={500}
                    spy={true}
                    offset={-70}
                  >
                    <Button color="inherit">{item.text}</Button>
                  </ScrollLink>
                ))}
              </div>
            )}
          </Toolbar>
        </AppBar>
      </HideOnScroll>

      {/* Drawer for Mobile */}
      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={handleDrawerToggle}
        sx={{
          display: { xs: "block", md: "none" },
          top: "64px",
        }}
      >
        <List sx={{ paddingTop: "72px" }}>
          {menuItems.map((item) => (
            <ListItem
              button
              key={item.to}
              onClick={() => {
                handleDrawerToggle();
                document
                  .querySelector(`#${item.to}`)
                  ?.scrollIntoView({ behavior: "smooth" });
              }}
            >
              <ListItemText primary={item.text} />
            </ListItem>
          ))}
        </List>
      </Drawer>

      {/* Spacer to account for fixed AppBar */}
      <Box sx={{ height: "64px" }} />
    </>
  );
}

export default AppBarComponent;
