import React, { useState } from "react";
import {
  Box,
  Typography,
  Grid,
  Paper,
  TextField,
  Button,
  Checkbox,
  FormControlLabel,
  useMediaQuery,
  useTheme,
  Divider,
  Link,
} from "@mui/material";
import axios from "axios";

const AccountDeletionPage = () => {
  const [step, setStep] = useState(1); // Track the current step in the process
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [confirmationInput, setConfirmationInput] = useState("");
  const [responseMessage, setResponseMessage] = useState("");
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post("/login/login", {
        email: email.toLowerCase(),
        password,
      });
      if (response.status === 200) {
        setStep(2); // Move to step 2 if login is successful
        setPassword(""); // Reset password state
      } else {
        setResponseMessage("Invalid login credentials.");
      }
    } catch (error) {
      setResponseMessage("There was an error during login.");
    }
  };

  const handleProceedToDeletion = () => {
    setStep(3); // Move to step 3
  };

  const handleDeleteRequest = async (e) => {
    e.preventDefault();
    if (confirmationInput !== "DELETE") {
      setResponseMessage("You must type DELETE to confirm.");
      return;
    }

    try {
      console.log(email);
      const response = await axios.post("/signup/deactivateAccount", {
        email: email,
      });
      if (response.status === 200) {
        setResponseMessage("Your account has been successfully deleted.");
        setStep(4); // Proceed to success step after deletion
        setConfirmationInput(""); // Reset confirmation input
        setEmail("");
        setIsConfirmed(false); // Reset checkbox state
      }
    } catch (error) {
      setResponseMessage("There was an error processing your request.");
    }
  };

  return (
    <Box padding={4}>
      <Paper
        elevation={3}
        sx={{
          padding: 4,
          maxWidth: 800,
          margin: "auto",
          backgroundColor: "#f5f5f5",
        }}
      >
        <Typography
          variant="h4"
          gutterBottom
          sx={{
            color: "#487de7",
            fontSize: isMobile ? "1.5rem" : "2.5rem",
            textAlign: "center",
          }}
        >
          Request Account Deletion
        </Typography>

        <Divider
          sx={{
            borderBottomWidth: 2,
            borderColor: "#487de7",
            marginY: "20px",
          }}
        />

        <Grid container spacing={4} justifyContent="center">
          <Grid item xs={12} md={8}>
            {/* Step 1: Account Login Section */}
            {step === 1 && (
              <>
                <Typography
                  variant="body1"
                  paragraph
                  sx={{
                    fontSize: isMobile ? "0.85rem" : "1rem",
                    textAlign: "center",
                  }}
                >
                  Please log in to confirm your identity before requesting to
                  delete your account.
                </Typography>

                <form onSubmit={handleLogin}>
                  <TextField
                    fullWidth
                    label="Email Address"
                    type="email"
                    variant="outlined"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    sx={{ marginBottom: 2 }}
                  />

                  <TextField
                    fullWidth
                    label="Password"
                    type="password"
                    variant="outlined"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                    sx={{ marginBottom: 2 }}
                  />

                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    fullWidth
                    sx={{
                      padding: "10px",
                      fontSize: "1rem",
                    }}
                  >
                    Log In
                  </Button>
                </form>
              </>
            )}

            {/* Step 2: Confirm Deletion Section */}
            {step === 2 && (
              <>
                <Typography
                  variant="body1"
                  paragraph
                  sx={{
                    fontSize: isMobile ? "0.85rem" : "1rem",
                    textAlign: "center",
                  }}
                >
                  You are logged in. Please confirm that you wish to delete your
                  account. Note that this action cannot be undone.
                </Typography>

                <Button
                  variant="contained"
                  color="error"
                  fullWidth
                  sx={{
                    padding: "10px",
                    fontSize: "1rem",
                  }}
                  onClick={handleProceedToDeletion}
                >
                  Proceed to Account Deletion
                </Button>

                <Typography
                  variant="body2"
                  color="textSecondary"
                  sx={{ textAlign: "center", marginTop: 2 }}
                >
                  Please note that while we will delete your account and
                  personal data, we are legally required to retain some
                  information. For more details, please review our{" "}
                  <Link
                    href="/PrivacyPolicy"
                    target="_blank"
                    underline="hover"
                    sx={{ color: "#487de7" }}
                  >
                    Privacy Policy
                  </Link>{" "}
                  and{" "}
                  <Link
                    href="/Terms&Conditions"
                    target="_blank"
                    underline="hover"
                    sx={{ color: "#487de7" }}
                  >
                    Terms and Conditions
                  </Link>
                  .
                </Typography>
              </>
            )}

            {/* Step 3: Type 'DELETE' to Confirm */}
            {step === 3 && (
              <>
                <Typography
                  variant="body1"
                  paragraph
                  sx={{
                    fontSize: isMobile ? "0.85rem" : "1rem",
                    textAlign: "center",
                  }}
                >
                  To confirm account deletion, please type{" "}
                  <strong>DELETE</strong> into the box below. This action is
                  irreversible.
                </Typography>

                <form onSubmit={handleDeleteRequest}>
                  <TextField
                    fullWidth
                    label="Type 'DELETE' to confirm"
                    variant="outlined"
                    value={confirmationInput}
                    onChange={(e) => setConfirmationInput(e.target.value)}
                    required
                    sx={{ marginBottom: 2 }}
                  />

                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={isConfirmed}
                        onChange={() => setIsConfirmed(!isConfirmed)}
                      />
                    }
                    label="I confirm I want to delete my account and associated data"
                    sx={{ marginBottom: 2 }}
                  />

                  <Button
                    variant="contained"
                    color="error"
                    type="submit"
                    fullWidth
                    disabled={confirmationInput !== "DELETE" || !isConfirmed}
                    sx={{
                      padding: "10px",
                      fontSize: "1rem",
                    }}
                  >
                    Request Account Deletion
                  </Button>
                </form>
              </>
            )}

            {/* Step 4: Success Message */}
            {step === 4 && (
              <Typography
                variant="h6"
                color="success.main"
                sx={{ textAlign: "center", marginTop: 2 }}
              >
                Your account has been successfully deleted.
              </Typography>
            )}

            {responseMessage && (
              <Typography
                variant="body2"
                color="textSecondary"
                sx={{ textAlign: "center", marginTop: 2 }}
              >
                {responseMessage}
              </Typography>
            )}
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
};

export default AccountDeletionPage;
