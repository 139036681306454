import React from "react";
import {
  Box,
  Typography,
  Grid,
  Paper,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import SidekickLogo from "../../assets/Sidekick-Avatar.png";

function AboutSection() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <Box id="about" padding={4}>
      <Paper
        elevation={3}
        sx={{
          padding: 4,
          maxWidth: 1100,
          margin: "auto",
          backgroundColor: "#f5f5f5",
        }}
      >
        <Typography
          variant="h4"
          gutterBottom
          style={{
            color: "#487de7",
            fontSize: isMobile ? "1.5rem" : "2.5rem",
            textAlign: "center",
          }}
        >
          Meet Sidekick: Your Life Assistant
        </Typography>
        <Grid container spacing={4} alignItems="center">
          <Grid item xs={12} md={6}>
            <img src={SidekickLogo} alt="Sidekick Logo" width="100%" />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
              variant="body1"
              paragraph
              style={{
                fontSize: isMobile ? "0.85rem" : "1rem",
                textAlign: "center",
              }}
            >
              Sidekick is a powerful AI chatbot crafted to be your go-to
              personal assistant. Whether you need to fill out forms, plan an
              event, read and prepare reports, store and retrieve documents,
              manage images, or just engage in a conversation, Sidekick has you
              covered. Its intuitive design allows you to access your stored
              information effortlessly within any chat. Built for iOS and
              Android, Sidekick integrates seamlessly into your daily life,
              making tasks simpler and faster.
            </Typography>
            <Typography
              variant="body2"
              color="textSecondary"
              style={{
                fontSize: isMobile ? "0.65rem" : "0.85rem",
                textAlign: "center",
              }}
            >
              Available now on the IOS Appstore and Google Play Store.
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
}

export default AboutSection;
