import React from "react";
import {
  Box,
  Typography,
  Card,
  CardContent,
  Avatar,
  Grid,
  IconButton,
  Paper,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import StarIcon from "@mui/icons-material/Star";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

// Review data
const reviews = [
  {
    username: "Abdul",
    avatar: "https://via.placeholder.com/150",
    rating: 4,
    text: "I think it's a powerful tool, that will make a difference.",
  },
  {
    username: "TheGreatAntonio",
    avatar: "https://via.placeholder.com/150",
    rating: 5,
    text: "This thing is awesome!",
  },
  {
    username: "BB",
    avatar: "https://via.placeholder.com/150",
    rating: 5,
    text: "Fantastic app! Easy to use, super helpful, and beautifully designed!",
  },
  {
    username: "Brendan",
    avatar: "",
    rating: 4,
    text: "Sidekick is super helpful for synthesizing information for content creation.",
  },
  {
    username: "KT",
    avatar: "",
    rating: 5,
    text: "Wow! I was impressed by its potential to make my job easy as an executive director of a small non-profit.",
  },
];

// Sort reviews by rating, highest first
const sortedReviews = reviews.sort((a, b) => b.rating - a.rating);

// Calculate average rating
const averageRating =
  sortedReviews.reduce((acc, review) => acc + review.rating, 0) /
  sortedReviews.length;

function ReviewSection() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const scrollContainerRef = React.useRef(null);

  const handleScroll = (direction) => {
    if (scrollContainerRef.current) {
      const scrollAmount = direction === "left" ? -300 : 300;
      scrollContainerRef.current.scrollBy({
        left: scrollAmount,
        behavior: "smooth",
      });
    }
  };

  return (
    <Box id="reviews" padding={4}>
      <Paper
        elevation={3}
        sx={{
          padding: 4,
          maxWidth: 1100,
          margin: "auto",
          backgroundColor: "#f5f5f5",
        }}
      >
        <Box
          // display="flex"
          // justifyContent="space-between"
          alignItems="center"
          marginBottom={2}
          flexDirection={isMobile ? "column" : "row"}
        >
          {isMobile ? (
            <Box>
              <Typography
                variant="h4"
                gutterBottom
                style={{
                  color: "#487de7",
                  fontSize: isMobile ? "1.5rem" : "2.5rem",
                  textAlign: "center",
                }}
              >
                Reviews
              </Typography>
              <Typography
                variant="h6"
                style={{
                  color: "#757575",
                  fontSize: isMobile ? "1.25rem" : "2rem",
                  textAlign: "center",
                }}
              >
                Avaerage Rating
              </Typography>
              <Typography
                variant="h6"
                style={{
                  color: "#757575",
                  fontSize: isMobile ? "1.25rem" : "2rem",
                  textAlign: "center",
                }}
              >
                {[...Array(5)].map((_, i) => (
                  <StarIcon
                    key={i}
                    style={{
                      color:
                        i < Math.round(averageRating) ? "#FFD700" : "#e0e0e0",
                    }}
                  />
                ))}
              </Typography>
              <Typography
                variant="h6"
                style={{
                  color: "#757575",
                  fontSize: isMobile ? "1.25rem" : "2rem",
                  textAlign: "center",
                }}
              >
                ({sortedReviews.length} reviews)
              </Typography>
            </Box>
          ) : (
            <>
              <Typography
                variant="h4"
                gutterBottom
                style={{
                  color: "#487de7",
                  fontSize: isMobile ? "1.5rem" : "2.5rem",
                  textAlign: "center",
                }}
              >
                Reviews
              </Typography>
              <Typography
                variant="h6"
                style={{
                  color: "#757575",
                  fontSize: isMobile ? "1.25rem" : "1.5rem",
                  textAlign: "center",
                }}
              >
                Average Rating:{" "}
                {[...Array(5)].map((_, i) => (
                  <StarIcon
                    key={i}
                    style={{
                      color:
                        i < Math.round(averageRating) ? "#FFD700" : "#e0e0e0",
                    }}
                  />
                ))}{" "}
                ({sortedReviews.length} reviews)
              </Typography>
            </>
          )}
        </Box>
        <Box position="relative">
          <Box
            ref={scrollContainerRef}
            display="flex"
            overflow="auto"
            paddingY={2}
            flexWrap="nowrap"
            sx={{
              gap: 2,
              // overflow: "auto",
              // padding: 2,
              "&::-webkit-scrollbar": {
                height: "4px",
              },
              "&::-webkit-scrollbar-track": {
                backgroundColor: "#f1f1f1",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "#888",
                borderRadius: "4px",
              },
              "&::-webkit-scrollbar-thumb:hover": {
                backgroundColor: "#555",
              },
              scrollbarWidth: "thin",
              scrollbarColor: "#888 #f1f1f1",
            }}
          >
            {sortedReviews.map((review, index) => (
              <Box key={index} width={isMobile ? "250px" : "500px"}>
                {" "}
                {/* Wider width for cards */}
                <Card
                  style={{
                    height: "200px",
                    width: isMobile ? "200px" : "300px",
                  }}
                >
                  {" "}
                  {/* Shorter height for cards */}
                  <CardContent
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      height: "100%",
                    }}
                  >
                    <Grid
                      container
                      alignItems="center"
                      justifyContent={isMobile ? "center" : "flex-start"}
                      spacing={2}
                    >
                      {/* <Grid item>
                        <Avatar src={review.avatar || "/default-avatar.png"} />
                      </Grid> */}
                      <Grid item>
                        <Typography
                          variant="h6"
                          style={{
                            fontSize: isMobile ? "0.85rem" : "1.5rem",
                            // textAlign: "center",
                          }}
                        >
                          {review.username}
                        </Typography>
                        <Typography variant="body2">
                          {[...Array(5)].map((_, i) => (
                            <StarIcon
                              key={i}
                              style={{
                                color:
                                  i < review.rating ? "#FFD700" : "#e0e0e0",
                              }}
                            />
                          ))}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Typography
                      variant="body2"
                      paragraph
                      style={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        display: "-webkit-box",
                        WebkitBoxOrient: "vertical",
                        WebkitLineClamp: isMobile ? 2 : 3, // Limit to 3 lines
                        textAlign: isMobile ? "center" : "left",
                      }}
                    >
                      "{review.text}"
                    </Typography>
                  </CardContent>
                </Card>
              </Box>
            ))}
          </Box>
          <IconButton
            onClick={() => handleScroll("left")}
            style={{
              position: "absolute",
              top: "50%",
              left: 0,
              transform: "translateY(-50%)",
              zIndex: 1,
            }}
            aria-label="Previous"
          >
            <NavigateBeforeIcon />
          </IconButton>
          <IconButton
            onClick={() => handleScroll("right")}
            style={{
              position: "absolute",
              top: "50%",
              right: 0,
              transform: "translateY(-50%)",
              zIndex: 1,
            }}
            aria-label="Next"
          >
            <NavigateNextIcon />
          </IconButton>
        </Box>
      </Paper>
    </Box>
  );
}

export default ReviewSection;
