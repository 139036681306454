import React from "react";
import { Box, Typography, Link, ListItem, ListItemText } from "@mui/material";

const PrivacyPolicy = () => {
  const CustomListItem = ({ letter, children }) => (
    <ListItem
      sx={{
        display: "flex",
        paddingLeft: 0,
        listStyleType: "none",
        alignItems: "flex-start",
      }}
    >
      <Typography
        variant="body1"
        component="span"
        sx={{
          minWidth: "2em",
          display: "inline-block",
          textAlign: "right",
          marginRight: "1em",
          fontFamily: "inherit !important",
        }}
      >
        {letter}
      </Typography>
      <ListItemText
        primary={children}
        sx={{ margin: 0 }}
        primaryTypographyProps={{
          sx: {
            fontFamily: "inherit !important",
          },
        }}
      />
    </ListItem>
  );

  return (
    <Box sx={{ padding: "3rem", backgroundColor: "#f5f5f5" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          backgroundColor: "white",
          padding: "2rem 1rem",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.7)", // Adjust the values as needed
          borderRadius: "8px",
        }}
      >
        <Typography
          variant="h6"
          style={{
            fontSize: "1.9rem",
            textAlign: "center",
            margin: "0rem 3rem",
            fontFamily: "inherit !important",
          }}
        >
          <strong>STEADY INNOVATION CORPORATION PRIVACY POLICY</strong>
        </Typography>
        <Typography
          variant="p"
          sx={{
            textAlign: "center",
            marginBottom: "20px",
            fontFamily: "inherit !important",
          }}
        >
          (the "<strong>Privacy Policy</strong>")
        </Typography>
        <Typography
          sx={{
            textAlign: "center",
            marginBottom: "20px",
            fontFamily: "inherit !important",
          }}
        >
          Effective Date:
        </Typography>
        <Typography style={{ fontFamily: "inherit !important" }}>
          Steady Innovation Corporation (o/a Steady Innovation, Sidekick and
          other brands) (“<strong>Sidekick</strong>"", “<strong>we</strong>”, “
          <strong>us</strong>”, or “<strong>our</strong>”) takes the security of
          your personal information very seriously. We understand and respect
          your right to privacy and value the trust you place in us. This
          Privacy Policy describes Sidekick’s privacy practices in relation to
          how we collect, use, store, disclose and protect your personal
          information, as well as your choices regarding use, access and
          correction of your personal information.
        </Typography>

        <Typography
          variant="p"
          marginTop={"20px"}
          style={{ fontFamily: "inherit !important" }}
        >
          1.&nbsp;&nbsp;&nbsp;<strong>Scope of this Privacy Policy</strong>
        </Typography>
        <Typography
          style={{ fontFamily: "inherit !important", marginBottom: "20px" }}
        >
          This Privacy Policy applies to information we may collect from
          visitors (“<strong>Visitors</strong>”) accessing our Sidekick platform
          and services as provided at{" "}
          <a
            style={{ color: "#00BFFF", fontFamily: "inherit !important" }}
            href="https://www.sidekick-ai.ninja"
            target="_blank"
            rel="noreferrer"
          >
            www.sidekick-ai.ninja
          </a>{" "}
          which may be redesignated from time to time (“<strong>Website</strong>
          ”) and the Sidekick mobile application (the Website and Sidekick
          mobile application shall be each an “<strong>Application</strong>” and
          collectively “<strong>Applications</strong>”) which may link to this
          Privacy Policy or from users or purchasers of our services through the
          Applications (“<strong>Clients</strong>”) (any reference to “
          <strong>Users</strong>”, “<strong>you</strong>” or “
          <strong>your</strong>” in this Privacy Policy refers to both Visitors
          and Clients, as the case may be).
        </Typography>
        <Typography
          style={{ fontFamily: "inherit !important", marginBottom: "20px" }}
        >
          Sidekick may change all or part of this Privacy Policy from time to
          time. We will notify you when this occurs. An up-to-date version of
          our Privacy Policy will be posted in the Applications. We will always
          seek your express consent to our collection, use and disclosure of
          your personal information, unless subsequent operations are
          sufficiently similar to ones already consented to. You may withdraw
          your consent at any time.
        </Typography>

        <Typography style={{ fontFamily: "inherit !important" }}>
          2.&nbsp;&nbsp;&nbsp;
          <strong>Our Collection and Use of Information</strong>
        </Typography>
        <Typography
          style={{ fontFamily: "inherit !important", marginBottom: "20px" }}
        >
          This Privacy Policy distinguishes between “personal information” and
          “non-personal information”. Personal information is information that
          can be used to identify an individual person. Non-personal information
          means data in a form that does not, on its own or in combination with
          other data sets, permit direct association with any specific
          individual. The types of information we collect and the purposes for
          collection are set out below.
        </Typography>
        <Typography
          style={{ fontFamily: "inherit !important", marginBottom: "20px" }}
        >
          <em>Personal Information</em> – When you contact us, order, subscribe,
          download, register, or use our services, we collect personal
          information. This personal information may include your name,
          occupation, shipping and residential address, email address, phone
          number, payment information, contact preferences, credit card
          information, and, if you provide it to us, your age and gender.
        </Typography>
        <Typography
          style={{ fontFamily: "inherit !important", marginBottom: "20px" }}
        >
          We use personal information to help us create, develop, operate,
          deliver, and improve our services, content and advertising, and for
          loss prevention and anti-fraud purposes. Some of the specific types of
          purposes for which we may use your personal information are as
          follows:
        </Typography>
        <Box sx={{ pl: 2 }}>
          <CustomListItem letter="(a)">
            When expressing an interest in obtaining additional information
            about our services, downloading our Applications or software
            updates, purchasing a product or contacting us, Sidekick may collect
            and use personal information, such as your contact information and
            billing information, to assist us in performing the requested
            services (i.e. to respond to your requests and to process payments).
          </CustomListItem>
          <CustomListItem letter="(b)">
            We may use your personal information, including date of birth, to
            assist with verifying your identity when you contact us.
          </CustomListItem>
          <CustomListItem letter="(c)">
            From time to time, we may use your personal information to inform
            you of our services and of software and firmware updates, to send
            you notices about products and services that you have purchased, to
            advise you of changes to our terms, conditions, and policies, and to
            advise you of enhancements to our services. Because this information
            is important to your interaction with Sidekick, you may not opt out
            of receiving these communications.
          </CustomListItem>
          <CustomListItem letter="(d)">
            We may contact you to survey you with respect to your use and views
            on our services or to send you promotional material. If you do not
            wish to receive such communications, you may opt out anytime by
            updating your account settings or by emailing info@sicdev.ninja.
          </CustomListItem>
        </Box>
        <Typography
          style={{ fontFamily: "inherit !important", marginBottom: "20px" }}
        >
          <em>Opting Out of Direct Marketing</em> - Where personal information
          is processed for direct marketing purposes, you have the right to
          object at any time to the processing of your personal information and
          your personal information will no longer be processed for such
          purposes.
        </Typography>
        <Typography
          style={{ fontFamily: "inherit !important", marginBottom: "20px" }}
        >
          <em>Online Navigational Information</em> – Our Applications, emails
          and advertisements may use "cookies" and other similar technologies to
          collect online navigational information, such as information about
          your web browser, your Internet Protocol (IP) address, your Internet
          Service Provider, your operating system, referring and exit web sites
          and applications, date/time stamps, clickstream data and the actions
          you take on our Applications. Cookies and similar technologies may
          also be placed by third party analytic services, such as Google
          analytics, or third party marketing services.
        </Typography>
        <Typography
          style={{ fontFamily: "inherit !important", marginBottom: "20px" }}
        >
          In some of our email or other electronic communications, we may use a
          "click-through URL" linked to content on our Applications. When the
          email reader clicks one of these URLs, they pass through a separate
          web server before arriving at the destination page contained within
          our Applications. We track this click-through data to help us
          determine interest in particular topics and measure the effectiveness
          of our customer communications. If you prefer not to be tracked in
          this way, you should not click text or graphic links in our electronic
          communication.
        </Typography>
        <Typography
          style={{ fontFamily: "inherit !important", marginBottom: "20px" }}
        >
          Online navigational information is aggregated and used to help us
          understand and analyze trends, to understand and analyze the
          effectiveness of our marketing, to administer the Applications, to
          learn about user behavior on the site, to gather demographic
          information about our user base as a whole, and to improve our
          services to better help you
        </Typography>
        <Typography
          style={{ fontFamily: "inherit !important", marginBottom: "20px" }}
        >
          Aggregated data with respect to online navigational information is
          considered non-personal information for the purposes of this Privacy
          Policy. If we do combine non-personal information with personal
          information, the combined information will be treated as personal
          information for as long as it remains combined. To the extent that IP
          addresses or similar identifiers are considered personal information,
          we also treat these identifiers as personal information for the
          purposes of this Privacy Policy.
        </Typography>
        <Typography style={{ fontFamily: "inherit !important" }}>
          3.&nbsp;&nbsp;&nbsp;
          <strong>Disclosure of Personal Information to Others</strong>
        </Typography>
        <Typography
          style={{ fontFamily: "inherit !important", marginBottom: "20px" }}
        >
          <em>Business Partners and Solution Providers</em> – We may provide
          your personal information to trusted business partners and solution
          providers that work with us to provide you with additional or enhanced
          services.{" "}
        </Typography>
        <Typography
          style={{ fontFamily: "inherit !important", marginBottom: "20px" }}
        >
          <em>Service Providers</em> - We may contract with third party service
          providers to assist us in performing business-related functions such
          as information processing, conducting security and background checks,
          managing and enhancing customer data, providing customer service,
          assessing your interest in services and conducting customer research
          or satisfaction surveys. To the extent a service provider has access
          to your personal information for such a purpose, we will ensure that
          they are obligated to use your personal information only for such
          purpose and that they are obligated to protect your information to
          standards at least as protective as those set out in this Privacy
          Policy.
        </Typography>
        <Typography
          style={{ fontFamily: "inherit !important", marginBottom: "20px" }}
        >
          <em>Third Party Offers</em> - We may present you with offers from
          trusted third parties. If we present you with such offers or
          solutions, it will be on an “opt-in” basis only.
        </Typography>
        <Typography
          style={{ fontFamily: "inherit !important", marginBottom: "20px" }}
        >
          <em>Legal Process</em> – It may be necessary by law, litigation or
          other legal process for us to disclose your personal information. Our
          policy is to notify you of any such legal process seeking access to
          your personal information unless we are prohibited by law from doing
          so or when the request is an emergency. We may also disclose
          information about you if we determine that disclosure is reasonably
          necessary to enforce our terms and conditions or protect our
          operations or users.{" "}
        </Typography>
        <Typography
          style={{ fontFamily: "inherit !important", marginBottom: "20px" }}
        >
          <em>Business Transitions</em> – If we are involved in a
          reorganization, merger, or the actual or prospective sale of our
          business or company (or a unit or division) we may disclose personal
          information to the relevant third party as part of the transaction. In
          that event, we will ensure that your personal information remains
          protected in accordance with the principles set out in this Privacy
          Policy.
        </Typography>
        <Typography
          style={{ fontFamily: "inherit !important", marginBottom: "20px" }}
        >
          <em>International transfer of information collected</em> – Sidekick
          primarily stores data about users in Canada. To facilitate Sidekick’s
          global operations, we may transfer and access such information from
          around the world, including from other countries in which Sidekick has
          operations. This Privacy Policy shall apply even if Sidekick transfers
          personal information about Users to other countries
        </Typography>
        <Typography style={{ fontFamily: "inherit !important" }}>
          4.&nbsp;&nbsp;&nbsp;<strong>Anonymized and Aggregated Data</strong>
        </Typography>
        <Typography
          style={{ fontFamily: "inherit !important", marginBottom: "20px" }}
        >
          We may anonymize and aggregate the data we collect across many users
          and may provide this anonymized, aggregated data to third parties.
          Such data will not identify you or be able to be de-anonymized such
          that it could identify you personally.{" "}
        </Typography>
        <Typography style={{ fontFamily: "inherit !important" }}>
          5.&nbsp;&nbsp;&nbsp;
          <strong>Protection of Personal Information</strong>
        </Typography>
        <Typography
          style={{ fontFamily: "inherit !important", marginBottom: "20px" }}
        >
          We take the security of your personal information very seriously. We
          use technical, administrative, and physical controls to maintain the
          security of your data. While we use industry-recognized security
          protocols to protect personal information, we cannot guarantee the
          safety of personal information from hacking or theft. Where a data
          breach occurs, we will notify affected individuals within 72 hours.
          Further, we will not transfer your personal information to any country
          that has privacy practices of a lesser standard than those set out in
          this Privacy Policy. If you have a security-related concern, please
          contact us at{" "}
          <a
            style={{ color: "#00BFFF", fontFamily: "inherit !important" }}
            href="mailto:hello@sicdev.ninja?subject=Your Subject Here&body=Your Message Here"
          >
            hello@sicdev.ninja
          </a>
          .
        </Typography>
        <Typography style={{ fontFamily: "inherit !important" }}>
          6.&nbsp;&nbsp;&nbsp;
          <strong>
            Retention of Personal Information and Right to Be Forgotten
          </strong>
        </Typography>
        <Typography
          style={{ fontFamily: "inherit !important", marginBottom: "20px" }}
        >
          You have the right to be forgotten. Upon your request and without
          undue delay, we will erase your personal information from our records,
          other than the personal information that we are required or permitted
          to retain by law, including any necessary information (including your
          contact information) to facilitate the investigation or resolution of
          a complaint, dispute or conflict arising from your use of the Sidekick
          services. Subject to your ability to request we erase your personal
          information as described above, we will retain your personal
          information for the period consistent with the purposes outlined in
          this Privacy Policy.
        </Typography>
        <Typography style={{ fontFamily: "inherit !important" }}>
          7.&nbsp;&nbsp;&nbsp;
          <strong>Access to your Personal Information</strong>
        </Typography>
        <Typography
          style={{ fontFamily: "inherit !important", marginBottom: "20px" }}
        >
          Upon reasonable request, we will provide you with access to your
          personal information that we retain for any purpose including
          requesting that we correct the data if it is inaccurate or delete the
          data if we are not required to retain it by law or for legitimate
          business purposes. We may decline to process requests that are
          frivolous, that jeopardize the privacy of others, are impractical, or
          for which access is not otherwise required by local law. Access,
          correction, or deletion requests can be made through{" "}
          <a
            style={{ color: "#00BFFF", fontFamily: "inherit !important" }}
            href="mailto:hello@sicdev.ninja?subject=Your Subject Here&body=Your Message Here"
          >
            hello@sicdev.ninja
          </a>
          .
        </Typography>
        <Typography style={{ fontFamily: "inherit !important" }}>
          8.&nbsp;&nbsp;&nbsp;<strong>Third Party Sites and Services</strong>
        </Typography>
        <Typography
          style={{ fontFamily: "inherit !important", marginBottom: "20px" }}
        >
          Our Applications may contain links to third party websites, products,
          and services and may also offer products or services from third
          parties. If such products and services are engaged, the information
          collected by third parties from you, including location data or
          contact details, is governed by their privacy policies. Sidekick is
          not responsible for these third-party websites, products or services
          and encourages you to review the privacy policies of such third party
          websites, products and services to understand their information
          practices.
        </Typography>
        <Typography style={{ fontFamily: "inherit !important" }}>
          9.&nbsp;&nbsp;&nbsp;<strong>Privacy Questions</strong>
        </Typography>
        <Typography
          style={{ fontFamily: "inherit !important", marginBottom: "20px" }}
        >
          If you have any questions or concerns about our Privacy Policy or
          associated practices, please contact our Data Protection Officer at{" "}
          <a
            style={{ color: "#00BFFF", fontFamily: "inherit !important" }}
            href="mailto:hello@sicdev.ninja?subject=Your Subject Here&body=Your Message Here"
          >
            hello@sicdev.ninja
          </a>
          . You can also always contact us by phone at our customer support
          number.
        </Typography>
      </Box>
    </Box>
  );
};

export default PrivacyPolicy;
