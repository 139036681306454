import React, { useState } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  Paper,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import axios from "axios";
import { Spinner } from "react-bootstrap";

function ContactSection() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [contactSent, setContactSent] = useState(false); // State to track contact submission
  const [sendingContact, setSendingContact] = useState(false);

  const sendContact = async (senderId, contact, contactFrom) => {
    console.log({ senderId, contact, contactFrom });
    try {
      const response = await axios.post("/user/giveFeedback", {
        senderId,
        feedback: contact,
        feedbackFrom: contactFrom,
      });

      if (response.data.ok) {
        console.log(response.data, "data");
        console.log("SENT");
        setContactSent(true); // Update state on successful submission
        setSendingContact(false);
      } else {
        console.log("Failed to send contact");
      }
    } catch (error) {
      console.error("Error sending contact:", error);
    }
  };

  const handleSubmit = (event) => {
    setSendingContact(true);
    event.preventDefault();
    const senderId = 0;
    sendContact(senderId, message, email);
  };

  const handleReset = () => {
    setContactSent(false); // Reset the state to show the form again
    setEmail("");
    setMessage("");
  };

  return (
    <Box id="contact" padding={4}>
      <Paper
        elevation={3}
        sx={{
          padding: 4,
          maxWidth: 1100,
          margin: "auto",
          backgroundColor: "#f5f5f5",
        }}
      >
        {sendingContact ? (
          <Box
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center", // Horizontal centering
              alignItems: "center", // Vertical centering
            }}
          >
            <Spinner
              animation="border"
              style={{
                width: "80px",
                height: "80px",
                color: "#487de7",
                borderWidth: "0.5rem",
              }}
            />
          </Box>
        ) : contactSent ? (
          <Box textAlign="center">
            <Typography variant="h4" gutterBottom style={{ color: "#487de7" }}>
              Contact Details Sent Successfully!
            </Typography>
            <Typography
              variant="body1"
              paragraph
              style={{
                fontSize: isMobile ? "0.85rem" : "1rem",
                textAlign: "center",
              }}
            >
              Thank you for reaching out. We appreciate your input and will get
              back to you if necessary.
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={handleReset}
              style={{ marginTop: "16px" }}
            >
              Submit Another Contact Request
            </Button>
          </Box>
        ) : (
          <>
            <Typography
              variant="h4"
              gutterBottom
              style={{
                color: "#487de7",
                fontSize: isMobile ? "1.5rem" : "2.5rem",
                textAlign: "center",
              }}
            >
              Contact Us
            </Typography>
            <Typography
              variant="body1"
              paragraph
              style={{
                fontSize: isMobile ? "0.85rem" : "1rem",
                textAlign: "center",
              }}
            >
              If you have any questions, contact, or need assistance, please
              feel free to reach out to us. You can use the form below to send
              us a message directly. We’re here to help!
            </Typography>
            <Typography
              variant="body1"
              paragraph
              style={{
                fontSize: isMobile ? "0.85rem" : "1rem",
                textAlign: "center",
              }}
            >
              Alternatively, you can email us at{" "}
              <a href="mailto:hello@sicdev.ninja" style={{ color: "#487de7" }}>
                hello@sicdev.ninja
              </a>{" "}
              and we will get back to you as soon as possible.
            </Typography>
            <form
              noValidate
              autoComplete="off"
              onSubmit={handleSubmit}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <TextField
                label="Email"
                type="email"
                fullWidth
                margin="normal"
                required
                variant="outlined"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <TextField
                label="Why are you reaching out?"
                fullWidth
                margin="normal"
                multiline
                rows={4}
                required
                variant="outlined"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              />
              <Button
                variant="contained"
                color="primary"
                type="submit"
                style={{
                  marginTop: "16px",
                  alignSelf: "center",
                }}
              >
                Send
              </Button>
            </form>
          </>
        )}
      </Paper>
    </Box>
  );
}

export default ContactSection;
