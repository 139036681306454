import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import LandingPage from "./Pages/LandingPage";
import PrivacyPolicy from "./Pages/PrivacyPolicy"; // Import the new Privacy Policy component
import axios from "axios";
import AccountDeletionPage from "./Pages/RequestDelete";
import TermsConditions from "./Pages/Terms&Conditions";

function App() {
  axios.defaults.baseURL = "https://server.sidekick-ai.ninja";

  return (
    <Router>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
        <Route path="/AccountDeletion" element={<AccountDeletionPage />} />
        <Route path="/Terms&Conditions" element={<TermsConditions />} />
        {/* Add more routes here */}
      </Routes>
    </Router>
  );
}

export default App;
