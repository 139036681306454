import React from "react";
import {
  Box,
  Typography,
  Grid,
  Paper,
  useMediaQuery,
  useTheme,
  Divider,
} from "@mui/material";
import FileButton from "../../assets/FileButton.jpg";
import GreenPark from "../../assets/GreenPark.jpg";
import Preferences from "../../assets/Preferences.jpg";
import Organization from "../../assets/Organization.jpg";
import ChattingWithSidekick from "../../assets/ChattingWithSidekick.jpg";
function HowItWorksSection() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <Box id="how-it-works" padding={4}>
      <Paper
        elevation={3}
        sx={{
          padding: 4,
          maxWidth: 1100,
          margin: "auto",
          backgroundColor: "#f5f5f5",
        }}
      >
        <Typography
          variant="h4"
          gutterBottom
          style={{
            color: "#487de7",
            fontSize: isMobile ? "1.5rem" : "2.5rem",
            textAlign: "center",
          }}
        >
          How It Works
        </Typography>

        {/* Profile Feature */}
        <Grid container spacing={4} alignItems="center">
          <Grid item xs={12} md={8}>
            <Typography
              variant="body1"
              paragraph
              style={{
                fontSize: isMobile ? "0.85rem" : "1rem",
                textAlign: "center",
              }}
            >
              Sidekick's AI-powered chat functionality is designed to assist you
              in both your professional and personal life. Whether you need to
              manage work tasks or streamline daily activities, Sidekick has you
              covered. The app excels at reading and summarizing long documents,
              preparing detailed reports, and even filing forms and applications
              on your behalf. With Sidekick, you can handle complex tasks with
              ease—whether it’s navigating the intricacies of a business project
              or managing your household paperwork.
            </Typography>
            {isMobile ? (
              <Grid item xs={12} md={4}>
                <img
                  style={{
                    borderRadius: "10px",
                    marginBottom: "20px",
                    // marginTop: "10px",
                  }}
                  src={ChattingWithSidekick}
                  alt="User Profile"
                  width="100%"
                />
              </Grid>
            ) : (
              ""
            )}
            <Typography
              variant="body2"
              color="textSecondary"
              style={{
                fontSize: isMobile ? "0.65rem" : "0.85rem",
                textAlign: "center",
              }}
            >
              Sidekick’s chat feature is optimized for both business and
              everyday use, ensuring you stay organized, informed, and ahead of
              your tasks.
            </Typography>
          </Grid>
          {isMobile ? (
            ""
          ) : (
            <Grid item xs={12} md={4}>
              <img
                style={{
                  borderRadius: "10px",
                }}
                src={ChattingWithSidekick}
                alt="User Profile"
                width="100%"
              />
            </Grid>
          )}
        </Grid>

        <Divider
          sx={{
            borderBottomWidth: 2,
            borderColor: "#487de7",
            marginTop: "20px",
            marginBottom: "20px",
          }}
        />

        {/* Uploading and Storing Documents/Images */}
        <Grid container spacing={4} alignItems="center" marginBottom={4}>
          <Grid item xs={12} md={4}>
            {isMobile ? (
              ""
            ) : (
              <img
                style={{
                  borderRadius: "10px",
                }}
                src={FileButton}
                alt="Upload Documents"
                width="100%"
              />
            )}
          </Grid>
          <Grid item xs={12} md={8}>
            <Typography
              variant="body1"
              paragraph
              style={{
                fontSize: isMobile ? "0.85rem" : "1rem",
                textAlign: "center",
              }}
            >
              Uploading documents and images to Sidekick is effortless. With
              just a few taps, you can securely store and organize all your
              important files, making them readily available whenever you need
              them. Sidekick’s AI can instantly analyze these documents,
              providing summaries and insights that save you time and enhance
              productivity.
            </Typography>
            {isMobile ? (
              <img
                style={{
                  borderRadius: "10px",
                  marginBottom: "20px",
                }}
                src={FileButton}
                alt="Upload Documents"
                width="100%"
              />
            ) : (
              ""
            )}
            <Typography
              variant="body2"
              color="textSecondary"
              style={{
                fontSize: isMobile ? "0.65rem" : "0.85rem",
                textAlign: "center",
              }}
            >
              Simply use the upload feature to select files from your device.
              Sidekick ensures secure storage, easy access, and smart analysis
              of your documents.
            </Typography>
          </Grid>
        </Grid>

        <Divider
          sx={{
            borderBottomWidth: 2,
            borderColor: "#487de7",
            marginTop: "20px",
            marginBottom: "20px",
          }}
        />

        {/* Retrieving Documents/Images */}
        <Grid container spacing={4} alignItems="center" marginBottom={4}>
          <Grid item xs={12} md={6} order={{ xs: 2, md: 1 }}>
            <Typography
              variant="body1"
              paragraph
              style={{
                fontSize: isMobile ? "0.85rem" : "1rem",
                textAlign: "center",
              }}
            >
              Need to retrieve a document or an image? Just ask Sidekick. The
              app will present your files within any conversation thread, and it
              can even summarize or provide key points from lengthy documents.
              Sidekick’s voice-to-text capability allows you to manage this
              entirely hands-free, perfect for when you’re on the go.
            </Typography>
            {isMobile ? (
              <img
                style={{
                  borderRadius: "10px",
                  marginBottom: "20px",
                }}
                src={GreenPark}
                alt="Retrieve Documents"
                width="100%"
              />
            ) : (
              "'"
            )}
            <Typography
              variant="body2"
              color="textSecondary"
              style={{
                fontSize: isMobile ? "0.65rem" : "0.85rem",
                textAlign: "center",
              }}
            >
              Voice-to-text is supported on compatible devices, enabling
              hands-free retrieval and summary of your files.
            </Typography>
          </Grid>
          {isMobile ? (
            ""
          ) : (
            <Grid item xs={12} md={6} order={{ xs: 1, md: 2 }}>
              <img
                style={{
                  borderRadius: "10px",
                }}
                src={GreenPark}
                alt="Retrieve Documents"
                width="100%"
              />
            </Grid>
          )}
        </Grid>

        <Divider
          sx={{
            borderBottomWidth: 2,
            borderColor: "#487de7",
            marginTop: "20px",
            marginBottom: "20px",
          }}
        />

        {/* Customizability */}
        <Grid container spacing={4} alignItems="center" marginBottom={4}>
          {isMobile ? (
            ""
          ) : (
            <Grid item xs={12} md={6}>
              <img
                style={{
                  borderRadius: "10px",
                  marginBottom: "20px",
                }}
                src={Preferences}
                alt="Customize Your App"
                width="100%"
              />
            </Grid>
          )}
          <Grid item xs={12} md={6}>
            <Typography
              variant="body1"
              paragraph
              style={{
                fontSize: isMobile ? "0.85rem" : "1rem",
                textAlign: "center",
              }}
            >
              Sidekick offers extensive customization options to match your
              style. Change the app’s primary color to reflect your personal
              preferences and switch on dark mode for a comfortable viewing
              experience during nighttime use. Additionally, Sidekick can be
              tailored to prioritize specific tasks, making it even more aligned
              with your workflow.
            </Typography>
            {isMobile ? (
              <img
                style={{
                  marginBottom: "20px",
                  borderRadius: "10px",
                }}
                src={Preferences}
                alt="Customize Your App"
                width="100%"
              />
            ) : (
              ""
            )}
            <Typography
              variant="body2"
              color="textSecondary"
              style={{
                fontSize: isMobile ? "0.65rem" : "0.85rem",
                textAlign: "center",
              }}
            >
              Personalize Sidekick to suit your preferences and workflow with
              just a few taps in the settings menu.
            </Typography>
          </Grid>
        </Grid>

        <Divider
          sx={{
            borderBottomWidth: 2,
            borderColor: "#487de7",
            marginTop: "20px",
            marginBottom: "20px",
          }}
        />

        {/* Organization Features */}
        <Grid container spacing={4} alignItems="center" marginBottom={4}>
          <Grid item xs={12} md={8} order={{ xs: 2, md: 1 }}>
            <Typography
              variant="body1"
              paragraph
              style={{
                fontSize: isMobile ? "0.85rem" : "1rem",
                textAlign: "center",
              }}
            >
              Manage your workspace efficiently with Sidekick’s organization
              feature. Whether you're creating or joining an organization,
              Sidekick facilitates collaboration while keeping your files secure
              and private to your user profile. This ensures that your personal
              data remains under your control, even within a shared workspace.
              Sidekick can also assist in filing forms and applications,
              ensuring accuracy and timeliness..
            </Typography>
            {isMobile ? (
              <img
                style={{
                  borderRadius: "10px",
                  marginBottom: "20px",
                }}
                src={Organization}
                alt="Organization Features"
                width="100%"
              />
            ) : (
              "'"
            )}
            <Typography
              variant="body2"
              color="textSecondary"
              style={{
                fontSize: isMobile ? "0.65rem" : "0.85rem",
                textAlign: "center",
              }}
            >
              The organization feature helps structure your work, keeps your
              files private, and assists with important tasks like filing forms
              and applications.
            </Typography>
          </Grid>
          {isMobile ? (
            ""
          ) : (
            <Grid item xs={12} md={4} order={{ xs: 1, md: 2 }}>
              <img
                style={{
                  borderRadius: "10px",
                }}
                src={Organization}
                alt="Organization Features"
                width="100%"
              />
            </Grid>
          )}
        </Grid>
      </Paper>
    </Box>
  );
}

export default HowItWorksSection;
